<template>
  <div class="container-fluid d-flex flex-column h-100">
    <div>
      <h4 class="text-center m-2 mt-3">测试页面</h4>
      <hr>
    </div>
    <div class="router-wrapper">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  name: 'Test',
  methods: {
    test () {
      axios.post('http://58.155.55.119:8080/test')
        .then(res => {
          alert(JSON.stringify(res))
        })
    }
  },
  mounted () {
  }
}
</script>

<style scoped lang="scss">
.router-wrapper {
  flex: 1;
  min-height: 200px;
}
</style>
