import utility from '@/common/utility'
import auth from '@/common/auth'

export default class AuthorityModel {
  constructor (props) {
    if (props) {
      this.resourceId = props.authorityId
      this.authorityId = props.authorityId
      this.authorityName = props.authorityName
      this.targetUrl = props.targetUrl
      this.httpMethod = props.httpMethod
      this.authorityType = props.authorityType
      this.parentId = props.parentId
      this.sortId = props.sortId
      this.staffName = props.staffName
      this.iconClass = props.iconClass
      this.createUserGuid = props.createUserGuid
      this.createTime = props.createTime
    }
    this.init()
  }

  init () {
    if (!this.resourceId) this.resourceId = 0
    if (!this.authorityId) this.authorityId = 0
    if (!this.authorityName) this.authorityName = ''
    if (!this.targetUrl) this.targetUrl = ''
    if (!this.httpMethod) this.httpMethod = ''
    if (!this.authorityType) this.authorityType = ''
    if (!this.parentId) this.parentId = 0
    if (!this.sortId) this.sortId = 0
    if (!this.staffName) this.staffName = ''
    if (!this.iconClass) this.iconClass = ''
    if (!this.createUserGuid) this.createUserGuid = auth.getUserInfo().userGuid
    if (!this.createTime) this.createTime = utility.getCurrentTime()
  }

  generatePrimaryKey () {
    this.authorityId = 0
    this.resourceId = this.authorityId
  }
}
