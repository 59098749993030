<template>
  <div class="authority-index">
    <my-search-bar>
      <el-button type="primary" @click="handleAddClick">添加</el-button>
    </my-search-bar>

    <el-table
      class="mt-3"
      :data="authorityList"
      border
      default-expand-all
      :height="tableHeight"
      header-cell-class-name="bg-info text-light"
      row-key="authorityId"
      >
      <el-table-column type="index" width="50" header-align="center" align="center" label="序号"></el-table-column>
      <el-table-column prop="authorityName" header-align="center" align="left" label="名称"></el-table-column>
      <el-table-column prop="targetUrl" header-align="center" align="center" label="URL地址"></el-table-column>
      <el-table-column prop="httpMethod" header-align="center" align="center" label="HTTP方法"></el-table-column>
      <el-table-column prop="authorityType" header-align="center" align="center" label="类型"></el-table-column>
      <el-table-column prop="iconClass" header-align="center" align="center" label="图标"></el-table-column>
      <el-table-column prop="sortId" width="50" header-align="center" align="center" label="排序"></el-table-column>
      <el-table-column prop="staffName" width="80" header-align="center" align="center" label="创建者"></el-table-column>
      <el-table-column :formatter="timeFormatter" width="160" prop="createTime" header-align="center" align="center" label="创建时间"></el-table-column>
      <el-table-column width="90" header-align="center" align="center" label="操作">
        <template v-if="scope.row.authorityId" slot-scope="scope">
          <el-tooltip class="item" effect="light" content="编辑" :open-delay="500" placement="top">
            <i @click="handleEditClick(scope.row)" class="text-info far fa-edit mr-2 operation-button"></i>
          </el-tooltip>
          <el-tooltip class="item" effect="light" content="删除" :open-delay="500" placement="top">
            <i @click="handleDeleteClick(scope.row)" class="text-danger far fa-trash-alt operation-button"></i>
          </el-tooltip>
        </template>
      </el-table-column>
    </el-table>

    <my-dialog ref="formDialog"
      :title="formDialogTitle"
      size="md"
      ok-text="保存"
      cancel-text="取消"
      @ok-click="handleOkClick">
      <authority-form
        ref="authorityForm"
        :authorityModel="authorityModel">
      </authority-form>
    </my-dialog>
  </div>
</template>

<script>
import MySearchBar from '@/components/MySearchBar/MySearchBar'
import MyDialog from '@/components/MyDialog/MyDialog'
import AuthorityForm from '@/views/AuthorityManage/AuthorityForm'
import AuthorityModel from '@/model/AuthorityModel'
import authorityService from '@/services/authorityService'
import PageOperation from '@/mixins/PageOperation'

export default {
  name: 'AuthorityIndex',
  mixins: [
    PageOperation
  ],
  components: {
    MyDialog,
    MySearchBar,
    AuthorityForm
  },
  data () {
    return {
      total: 0,
      authorityList: [],
      authorityModel: new AuthorityModel(),
      formDialogTitle: ''
    }
  },
  methods: {
    handleAddClick () {
      this.formDialogTitle = '添加权限信息'
      this.authorityModel = new AuthorityModel()
      this.$refs.formDialog.open()
    },
    handleEditClick (row) {
      this.formDialogTitle = '编辑权限信息'
      this.authorityModel = new AuthorityModel(row)
      this.$refs.formDialog.open()
    },
    handleDeleteClick (row) {
      this.$confirm('您确定要删除吗？')
        .then(() => {
          let _authorityModel = new AuthorityModel(row)
          authorityService.delete(_authorityModel.resourceId)
            .then(res => {
              window.console.log(res)
              if (res.data.code === 1) {
                this.$message({
                  type: 'success',
                  message: '删除成功！'
                })
                this._getAuthorityList()
              } else {
                this.$message({
                  type: 'error',
                  message: res.data.msg
                })
              }
            })
            .catch(err => {
              window.console.log(err)
              this.$message({
                type: 'error',
                message: '删除失败！'
              })
            })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '您已取消删除！'
          })
        })
    },
    handleOkClick () {
      this.$refs.authorityForm.validate()(valid => {
        if (valid) {
          if (!this.authorityModel.resourceId) {
            this._save()
          } else {
            this._edit()
          }
        } else {
          this.$message({
            type: 'error',
            message: '请检查您的输入！'
          })
        }
      })
    },
    _getAuthorityList () {
      authorityService.treeList()
        .then(res => {
          if (res.data.code === 1) {
            this.total = res.data.data.length
            this.authorityList = res.data.data
            this.$message({
              type: 'success',
              message: '列表加载成功！'
            })
          } else {
            this.$message({
              type: 'error',
              message: res.data.msg
            })
          }
        })
        .catch(err => {
          window.console.log(err)
          this.$message({
            type: 'error',
            message: '列表加载失败！'
          })
        })
    },
    _save () {
      authorityService.add(this.authorityModel)
        .then(res => {
          window.console.log(res)
          if (res.data.code === 1) {
            this.$refs.formDialog.close()
            this._getAuthorityList()
            this.$message({
              type: 'success',
              message: '添加成功！'
            })
          } else {
            this.$message({
              type: 'error',
              message: res.data.msg
            })
          }
        })
        .catch(err => {
          this.$message({
            type: 'error',
            message: '添加失败！'
          })
          window.console.log(err.request.response)
        })
    },
    _edit () {
      authorityService.edit(this.authorityModel)
        .then(res => {
          window.console.log(res)
          if (res.data.code === 1) {
            this.$refs.formDialog.close()
            this._getAuthorityList()
            this.$message({
              type: 'success',
              message: '修改成功！'
            })
          } else {
            this.$message({
              type: 'error',
              message: res.data.msg
            })
          }
        })
        .catch(err => {
          this.$message({
            type: 'error',
            message: '修改失败！'
          })
          window.console.log(err.request.response)
        })
    }
  },
  created () {
    this._getAuthorityList()
    this.refreshMethod = this._getAuthorityList
  }
}
</script>
