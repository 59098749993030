<template>
  <el-form
    ref="elForm"
    :rules="rules"
    :model="authorityModel"
    label-width="100px"
    >
    <el-form-item label="名称：" prop="authorityName">
      <el-input
        type="text"
        clearable
        v-model="authorityModel.authorityName" />
    </el-form-item>
    <el-form-item label="URL地址：" prop="targetUrl">
      <el-input
        type="text"
        clearable
        v-model="authorityModel.targetUrl" />
    </el-form-item>
    <el-form-item label="HTTP方法：" prop="httpMethod">
      <el-select v-model="authorityModel.httpMethod" clearable>
        <el-option
          v-for="httpMethod in httpMethods"
          :key="httpMethod.dictionaryGuid"
          :label="httpMethod.dictionaryValue"
          :value="httpMethod.dictionaryValue"
          >
        </el-option>
      </el-select>
    </el-form-item>
    <el-form-item label="类型：" prop="authorityType">
      <el-select v-model="authorityModel.authorityType" clearable>
        <el-option
          v-for="authorityType in authorityTypes"
          :key="authorityType.dictionaryGuid"
          :label="authorityType.dictionaryValue"
          :value="authorityType.dictionaryValue"
          >
        </el-option>
      </el-select>
    </el-form-item>
    <el-form-item label="图标：" prop="iconClass">
      <el-input
        type="text"
        clearable
        v-model="authorityModel.iconClass" />
    </el-form-item>
    <el-form-item label="父节点：" prop="parentId">
      <my-select-tree
        v-if="nodes[0].children && nodes[0].children.length"
        v-model="authorityModel.parentId"
        nodeKey="authorityId"
        labelKey="authorityName"
        :nodes="nodes"
        :props="props">
      </my-select-tree>
    </el-form-item>
    <el-form-item label="排序：" prop="sortId">
      <el-input-number
        v-model="authorityModel.sortId"
        :step="1"
        :min="0"
        :precision="0"
        step-strictly>
      </el-input-number>
    </el-form-item>
  </el-form>
</template>

<script>
import AuthorityModel from '@/model/AuthorityModel'
import MySelectTree from '@/components/MyTree/MySelectTree'
import authorityService from '@/services/authorityService'
import dictionaryService from '@/services/dictionaryService'
import PageInfo from '@/support/PageInfo'
import config from '@/config'

export default {
  name: 'AuthorityForm',
  components: {
    MySelectTree
  },
  props: {
    authorityModel: {
      type: AuthorityModel,
      default () {
        return new AuthorityModel()
      }
    }
  },
  data () {
    return {
      httpMethods: [],
      authorityTypes: [],
      nodes: [
        new AuthorityModel({ authorityId: 0, authorityName: '根节点' })
      ],
      props: {
        label: 'authorityName',
        children: 'children'
      },
      rules: {
        authorityName: [
          {
            required: true,
            message: '请输入名称',
            trigger: 'blur'
          }
        ],
        httpMethod: [
          {
            required: true,
            message: '请选择HTTP方法',
            trigger: 'blur'
          }
        ],
        authorityType: [
          {
            required: true,
            message: '请选择类型',
            trigger: 'blur'
          }
        ]
      }
    }
  },
  computed: {
  },
  methods: {
    validate () {
      return this.$refs.elForm.validate
    },
    _getNodes () {
      authorityService.treeList()
        .then(res => {
          if (res.data.code === 1) {
            this.$set(this.nodes[0], 'children', res.data.data)
            console.log(this.nodes)
          } else {
            this.$message({
              type: 'error',
              message: res.data.msg
            })
          }
        })
        .catch(err => {
          window.console.log(err.request.response)
          this.$message({
            type: 'error',
            message: '加载数据错误！'
          })
        })
    },
    _getHttpMethods () {
      dictionaryService.list(new PageInfo({ pageSize: config.bigPageSize, pageIndex: 1, fieldOrder: 'sortId' }), { dictionaryKey: 'authorityHttpMethod' })
        .then(res => {
          if (res.data.code === 1) {
            this.httpMethods = res.data.data
          } else {
            this.$message({
              type: 'error',
              message: '获取数据失败！'
            })
          }
        })
        .catch(err => {
          this.$message({
            type: 'error',
            message: '获取数据失败！'
          })
          console.log(err.request.response)
        })
    },
    _getAuthorityTypes () {
      dictionaryService.list(new PageInfo({ pageSize: config.bigPageSize, pageIndex: 1, fieldOrder: 'sortId' }), { dictionaryKey: 'authorityType' })
        .then(res => {
          if (res.data.code === 1) {
            this.authorityTypes = res.data.data
          } else {
            this.$message({
              type: 'error',
              message: '获取数据失败！'
            })
          }
        })
        .catch(err => {
          this.$message({
            type: 'error',
            message: '获取数据失败！'
          })
          console.log(err.request.response)
        })
    }
  },
  created () {
    this._getNodes()
    this._getHttpMethods()
    this._getAuthorityTypes()
  }
}
</script>
